// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Automatedemailsending from "../../blocks/automatedemailsending/src/Automatedemailsending";
import Cfpullcustomeroverduecredit from "../../blocks/cfpullcustomeroverduecredit/src/Cfpullcustomeroverduecredit";
import PerformanceTracker from "../../blocks/performancetracker/src/PerformanceTracker";
import PtCoursesScreen from "../../blocks/performancetracker/src/PtCoursesScreen";
import PtTestsForCourseScreen from "../../blocks/performancetracker/src/PtTestsForCourseScreen";
import PtStudentsOnCourseScreen from "../../blocks/performancetracker/src/PtStudentsOnCourseScreen";
import PtTestScoresForStudentScreen from "../../blocks/performancetracker/src/PtTestScoresForStudentScreen";
import PtBasicUserCoursesScreen from "../../blocks/performancetracker/src/PtBasicUserCoursesScreen";
import PtBasicUserAllCoursesScreen from "../../blocks/performancetracker/src/PtBasicUserAllCoursesScreen";
import PtStudentRankingScreen from "../../blocks/performancetracker/src/PtStudentRankingScreen";
import PtStudentInfoScreen from "../../blocks/performancetracker/src/PtStudentInfoScreen";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Cfpullcustomertransactions from "../../blocks/cfpullcustomertransactions/src/Cfpullcustomertransactions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Cfpullcustomerpayments from "../../blocks/cfpullcustomerpayments/src/Cfpullcustomerpayments";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Notifications from "../../blocks/notifications/src/Notifications";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Cfpullcreditfacilitydata from "../../blocks/cfpullcreditfacilitydata/src/Cfpullcreditfacilitydata";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Cfpullcustomerinformation from "../../blocks/cfpullcustomerinformation/src/Cfpullcustomerinformation";
import Cfpullcustomercreditinformation from "../../blocks/cfpullcustomercreditinformation/src/Cfpullcustomercreditinformation";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ProjectReporting from "../../blocks/projectreporting/src/ProjectReporting";



const routeMap = {
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  Automatedemailsending: {
    component: Automatedemailsending,
    path: "/Automatedemailsending"
  },
  Cfpullcustomeroverduecredit: {
    component: Cfpullcustomeroverduecredit,
    path: "/Cfpullcustomeroverduecredit"
  },
  ProjectReporting: {
    component: ProjectReporting,
    path: "/./src/ProjectReporting.web.tsx"
  },
  PerformanceTracker: {
    component: PerformanceTracker,
    path: "/PerformanceTracker"
  },
  PtCoursesScreen: {
    component: PtCoursesScreen,
    path: "/PtCoursesScreen"
  },
  PtTestsForCourseScreen: {
    component: PtTestsForCourseScreen,
    path: "/PtTestsForCourseScreen"
  },
  PtStudentsOnCourseScreen: {
    component: PtStudentsOnCourseScreen,
    path: "/PtStudentsOnCourseScreen"
  },
  PtTestScoresForStudentScreen: {
    component: PtTestScoresForStudentScreen,
    path: "/PtTestScoresForStudentScreen"
  },
  PtBasicUserCoursesScreen: {
    component: PtBasicUserCoursesScreen,
    path: "/PtBasicUserCoursesScreen"
  },
  PtBasicUserAllCoursesScreen: {
    component: PtBasicUserAllCoursesScreen,
    path: "/PtBasicUserAllCoursesScreen"
  },
  PtStudentRankingScreen: {
    component: PtStudentRankingScreen,
    path: "/PtStudentRankingScreen"
  },
  PtStudentInfoScreen: {
    component: PtStudentInfoScreen,
    path: "/PtStudentInfoScreen"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Cfpullcustomertransactions: {
    component: Cfpullcustomertransactions,
    path: "/Cfpullcustomertransactions"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Cfpullcustomerpayments: {
    component: Cfpullcustomerpayments,
    path: "/Cfpullcustomerpayments"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  Cfpullcreditfacilitydata: {
    component: Cfpullcreditfacilitydata,
    path: "/Cfpullcreditfacilitydata"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  Cfpullcustomerinformation: {
    component: Cfpullcustomerinformation,
    path: "/Cfpullcustomerinformation"
  },
  Cfpullcustomercreditinformation: {
    component: Cfpullcustomercreditinformation,
    path: "/Cfpullcustomercreditinformation"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;